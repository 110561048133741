import React, { useCallback, useEffect, useState } from "react";
import { getPersonalData } from "../../Services/apis";
import { updateProfile } from "../../Services/apis";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_DATA } from "../../Redux/types";

export default function PersonalData({ history }) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [errMsg, setErrmsg] = useState("");
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();

  const [inputObj, setInputObj] = useState({
    first_name: "",
    code: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    country_id: "",
    state_id: "",
    city: "",
    address: "",
    phone_number: "",
    username: "",
    email: "",
    balance: 0,
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const init = useCallback(() => {
    getPersonalData()
      .then((res) => {
        setInputObj({
          ...inputObj,
          code: res.user.code,
          first_name: res.user.details.first_name,
          last_name: res.user.details.last_name,
          phone: res?.user?.details?.phone_number,
          // date_of_birth: res.user.details.date_of_birth,
          gender: res.user.details.gender,
          country_id: res.user.details.country_id,
          state_id: res.user.details.state_id,
          city: res.user.details.city,
          address: res.user.details.address,
          phone_number: res.user.details.phone_number,
          username: res.user.username,
          email: res.user.email,
          balance: res.user.available_balance,
        });
        setCountries(res.countries);
        setStates(res.states);
      })
      .catch((err) => {});
  }, []);

  const handleChange = (e) => {
    e.preventDefault();

    setInputObj({
      ...inputObj,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    init();
  }, []);

  const submit = (e) => {
    e.preventDefault();
    const payload = {
      email: inputObj.email,
      details: {
        first_name: inputObj.first_name,
        last_name: inputObj.last_name,
      },
    };
    if (
      inputObj.email === null ||
      inputObj.first_name === null ||
      inputObj.last_name === null
    ) {
      setErrmsg("All fields are required");
      setErr(true);
    } else {
      setErr(false);
      updateProfile(payload)
        .then((res) => {
          console.log(res);
          const { data } = res;
          setSubmitting(false);
          dispatch({
            type: SET_USER_DATA,
            payload: {
              user: { ...data?.user, details: data?.details },
              isAuthenticated: true,
            },
          });
          toast.success(res?.message);
          history.goBack();
        })
        .catch((err) => {
          console.log(err.response);
          setSubmitting(false);
          if (err.response.status === 422) {
            let errors = Object.values(err?.response?.data?.errors);
            errors = errors?.flat();
            toast.error(errors);
          }
          toast.error(err?.response?.data?.message);
        });
    }
  };
  return (
    <>
      <div id="MainContent" className="">
        <div className="Riquadro">
          {/*<div className="TopSX">
                        <div className="TopDX"><h3>User Details</h3></div>
                    </div>*/}
          <div className="CntSX">
            <div className="CntDX">
              <div id="s_w_PC_PC_panelSquare">
                <div className="RiquadroNews Reg">
                  <div className="Cnt">
                    <form className="personal-profile">
                      <div className="flex">
                        <div className="form-row">
                          <label>Account Id</label>
                          <input
                            name="code"
                            value={inputObj.code}
                            onChange={(e) => handleChange(e)}
                            disabled={true}
                          />
                        </div>
                        <div className="form-row">
                          <label>Contact Number</label>
                          <input
                            name="phone"
                            required
                            value={inputObj.phone}
                            onChange={(e) => handleChange(e)}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="form-row">
                          <label>Username</label>
                          <input
                            name="username"
                            value={inputObj.username}
                            onChange={(e) => handleChange(e)}
                            disabled={true}
                            autoComplete={false}
                          />
                        </div>
                        <div className="form-row">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            value={inputObj.email}
                            autoComplete={false}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="form-row">
                          <label>First Name</label>
                          <input
                            name="first_name"
                            required
                            value={inputObj.first_name}
                            autoComplete={false}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <div className="form-row">
                          <label>Last Name</label>
                          <input
                            required
                            name="last_name"
                            value={inputObj.last_name}
                            autoComplete={false}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "2rem auto",
                        }}
                      >
                        <button
                          type="submit"
                          className="update-btn"
                          onClick={submit}
                        >
                          UPDATE
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="BtmSX">
                    <div className="BtmDX"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="BtmSX">
            <div className="BtmDX"></div>
          </div>
        </div>
      </div>
    </>
  );
}
