import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { verifyTransaction } from "../Services/apis";
import { useSelector } from "react-redux";
import JackpotLayout from "./layout/JackpotLayout";

const PaymentVerification = ({history}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [trxRef, setTrxRef] = useState(null); 
  const [message, setMessage] = useState(null); 
  const [success, setSuccess] = useState(false); 
  const [loading, setLoading] = useState(true); 
  const {type: gateway} = useParams();

  useEffect(() => {
    switch (gateway) {
      case 'monnify':
        setTrxRef(queryParams.get('paymentReference'))
        break;
      case 'flutterwave':
        const status = queryParams.get('status');
        if (status === 'cancelled') {
          setMessage('Transaction was cancelled');
          setSuccess(false);
        } else {
          setTrxRef(queryParams.get('tx_ref'));
        }
        break;
      case 'paystack':
        setTrxRef(queryParams.get('trxref'))
        break;
      default:
        break;
    }
  }, [gateway]);


  useEffect(() => {
    async function verify(data) {
      await verifyTransaction(data).then(res => {
        setLoading(false);
        if(res.success) {
          setSuccess(true);
          setMessage('Your payment was successful!!')
          setTimeout(() => {
            window.location.href = '/';
          }, [3000]);
        } else {
          setMessage(res.message);
          setSuccess(false);
        }
      }).catch(err => setLoading(false) | setSuccess(false) | setMessage('We are unable to verify payment'));
    }

    if(trxRef) verify({paymentChannel: gateway, trxRef})

  }, [trxRef]);

  return (
    <JackpotLayout>
      <div className="homeShop logged" style={{paddingTop: 40, textAlign: 'center'}}>
          {loading && 
          <>
            <img src="/img/loader.svg" height={100} />
            <h1>Confirming payment...</h1>
          </>}
          {message && 
            <div style={{padding: 20, backgroundColor: success ? 'green' : 'red'}}>
              <h1 style={{color: 'white'}}>{message}</h1>
            </div>
          }
      </div>
    </JackpotLayout>
    
  );
};

export default PaymentVerification;
