import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function SuccessfulPayment() {
  return (
    <div id="MainContent" className="">
      <div className="Riquadro">
        <div
          className="success"
          style={{
            textAlign: "center",
            background: "white",
            width: "100%",
            margin: "0 auto",
            padding: "7rem",
            height: "100vh",
          }}
        >
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              fontSize: "5rem",
              color: "green",
              marginRight: "1rem",
            }}
            className=""
          />
          <h1>Transaction Successful.</h1>
        </div>
      </div>
    </div>
  );
}
