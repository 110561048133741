import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bankWithdrawal, getAllBanks, getBankDetails, getWithdrawalInfo, verifyBankAccount } from "../../Services/apis";
import { UPDATE_USER_BALANCE } from "../../Redux/types";
import { toast } from "react-toastify";
import '../../Assets/scss/_payments.scss'
const gateways = [
  { slug: "online", name: "Bank Transfer" },
  { slug: "shop", name: "Shop Withdrawal" },
  { slug: "pending", name: "Pending Withdrwals" },
];

export function Withdrawal({ history }) {
  const { user } = useSelector((state) => state.auth);
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState({
    slug: "online",
    name: "Bank Transfer",
  });
  const [formData, setFormData] = useState({
    amount: 0,
    bank_code: "",
    account_number: '',
    account_name: '',
    recipient_code: '',
    type: activeTab.slug
  });
  const [paymentSuccess, setPaymentSuccess] = useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [withdrawalData, setWithdrawalData] = useState({});
  const [bankData, setBankData] = useState(null);
  const [pendings, setPendings] = useState([]);

  const updateAmount = (value) => {
    if (value === 0) {
      setFormData({ ...formData, amount: 0 })
      return;
    }

    let currentAmount = formData.amount;
    if (currentAmount === "") {
      currentAmount = 0;
    }
    const newAmount = currentAmount + value;
    setFormData({ ...formData, amount: newAmount })
  };

  useEffect(() => {
    if (user?.email === null) {
      history.replace("/Account/PersonalInfo");
    }
  }, [user]);

  useEffect(() => {
    fetchBanks();
    fetchBankData();
    getPendings();
  }, []);

  useEffect(() => {
    if (bankData) {
      setFormData({
        ...formData,
        account_number: bankData.account_number,
        account_name: bankData.account_name,
        bank_code: bankData.bank_code
      })
    }
  }, [bankData]);

  const fetchBanks = (page) => {
    getAllBanks()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchBankData = () => {
    getBankDetails()
      .then((res) => {
        if (res.success) {
          setBankData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const withdraw = () => {

    bankWithdrawal(formData)
      .then((r) => {
        setLoading(false);
        if (r.success) {
          dispatch({ type: UPDATE_USER_BALANCE, payload: r.data.available_balance });
          setFormData({});
          if (activeTab.slug === 'online') {
            toast.success(r.message);
          } else {
            setShow(true);
            setWithdrawalData(r?.data);
          }
        } else {
          toast.error(r.message);
          setShowErr(true);
          setErrMsg(r?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error occured");
        setLoading(false);
      });
  };

  const getPendings = async () => {
    await getWithdrawalInfo().then(res => {
      console.log(res);
    })
  }

  const doVerify = async (account_number) => {
    setLoading(true);
    await verifyBankAccount({
      account_number,
      bank_code: formData.bank_code,
    })
      .then(res => {
        setLoading(false);
        if (res.success) {
          setFormData({
            ...formData,
            account_name: res.data.account_name,
            recipient_code: res.data.recipient_code,
            account_number
          })
        } else {
          setFormData({
            ...formData,
            account_name: '',
            recipient_code: ''
          })
          toast.error(res.message);
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.response.data.message);
      })
  };

  return (
    <>
      <div id="MainContent" className="">
        <div className="Riquadro">
          <div className="CntSX">
            <div className="CntDX">
              <div className="payments">
                <div className="RiquadroSrc">
                  <div className="Cnt">
                    <div>
                      <div className="page__head">
                        <div className="page__head-item">
                          <h1> Withdraw Funds</h1>
                        </div>
                        {show && (
                          <p className="code-card" style={{ background: "green", padding: 20, color: 'white' }}>
                            {" "}
                            Your Withdrawal Pin is:{" "}
                            <strong>{withdrawalData?.requests?.withdraw_code}</strong>
                            <br />
                            Take to the shop for payment.
                          </p>
                        )}
                        {showErr && (
                          <p className="code-card" style={{ background: "red", padding: 20, color: 'white' }}>
                            {" "}
                            {errMsg}
                          </p>
                        )}
                      </div>
                      <div className="t-menu pl30 pr30">
                        {gateways.map((gateway) => (
                          <a
                            className={`t-menu__item ${gateway.slug === activeTab.slug ? "active" : ""
                              }`}
                            onClick={() => {
                              setActiveTab(gateway);
                              setFormData({ ...formData, type: gateway.slug })
                            }}
                            key={gateway.slug}
                            href="#"
                          >
                            <strong className="t-menu__item-title">
                              {" "}
                              {gateway.name}
                            </strong>
                          </a>
                        ))}
                      </div>
                      {paymentSuccess !== "" && (
                        <div className="info-box green">{paymentSuccess}</div>
                      )}
                      <div className="page__body pt15 pb15">
                        {/* <div className="wrap mt10">
                          <div className="pull-right txt-deepgray">
                            {" "}
                            All fields are required
                          </div>
                        </div> */}
                        <div className="divide-holder">
                          <div className="one-half">
                            <div
                              className="form-row"
                              style={{ paddingRight: "1rem" }}
                            >
                              <div className="form-label">
                                <strong>
                                  {" "}
                                  Deposit Amount(
                                  {SportsbookGlobalVariable.Currency})
                                </strong>
                              </div>
                              <div className="form-input">
                                <input
                                  name="amount"
                                  className="big"
                                  type="number"
                                  autoComplete="off"
                                  step="100"
                                  maxLength="5"
                                  min="100"
                                  max="10000"
                                  value={formData.amount}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      amount: e.target.value,
                                    })
                                  }
                                />
                                <div className="form-input--stake">
                                  {" "}
                                  Min {SportsbookGlobalVariable.MinWithdrawal}
                                </div>
                              </div>
                              <div className="quickstake mt10">
                                <div
                                  className="quickstake__item"
                                  onClick={() => updateAmount(0)}
                                >
                                  {" "}
                                  Clear
                                </div>
                                <div
                                  className="quickstake__item"
                                  onClick={() => updateAmount(100)}
                                >
                                  {" "}
                                  +100
                                </div>
                                <div
                                  className="quickstake__item"
                                  onClick={() => updateAmount(200)}
                                >
                                  {" "}
                                  +200
                                </div>
                                <div
                                  className="quickstake__item"
                                  onClick={() => updateAmount(500)}
                                >
                                  {" "}
                                  +500
                                </div>
                                <div
                                  className="quickstake__item"
                                  onClick={() => updateAmount(1000)}
                                >
                                  {" "}
                                  +1000
                                </div>
                              </div>
                            </div>
                            {SportsbookGlobalVariable.EnableBankAcct == 1 && activeTab.slug === 'online' &&
                              <>
                                <div className="form-select my-1">
                                  <label className="">Bank:</label>
                                  <select
                                    name="bank_code"
                                    type="text"
                                    value={formData.bank_code}
                                    onChange={(e) => setFormData({ ...formData, bank_code: e.target.value })}
                                  >
                                    <option value="Card">Select a bank..</option>
                                    {data &&
                                      data?.map((item) => (
                                        <option value={item.code}>{item?.name}</option>
                                      ))}
                                  </select>
                                </div>
                                <div className=" my-1">
                                  <label className="">Account Number:</label>
                                  <input
                                    name="account"
                                    type="text"
                                    className="deposit-input"
                                    value={formData.account_number}
                                    onChange={(e) => {
                                      setFormData({ ...formData, account_number: e.target.value });
                                      if (e.target.value.length === 10) {
                                        doVerify(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                                <div className=" my-1">
                                  <label className="">Account Name:</label>
                                  <input
                                    name="amount"
                                    type="text"
                                    disabled={true}
                                    className="deposit-input"
                                    value={formData.account_name}
                                  />
                                </div>
                              </>
                            }
                            <button
                              className="btn green mt20 mb20"
                              onClick={withdraw}
                            >
                              {" "}
                              Withdraw
                            </button>
                          </div>
                          <div className="mt20 txt-deepgray">
                            <h3 className="txt-darkgreen ">
                              {" "}
                              Bank Transfer
                            </h3>
                            <p className="">
                              <strong>Withdrawal via Bank Transfer is for Free.</strong>
                              Withdrawals via bank transfer take up to 24 hours after authorisation and are only actioned during banking hours.
                              If your bank account is not listed above, kindly add your bank account details below.
                            </p>
                            <p className="">
                              You can <strong>cancel withdrawals</strong> that
                              have not yet been processed.
                            </p>
                            <p className="">
                              <strong
                                onClick={() => setActiveTab({
                                  slug: 'pending',
                                  name: 'Pending Withdrawals'
                                })}
                                className="txt-blue undefined">
                                {" "}
                                View Pending Withdrawals
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}