import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTopCasinoGame } from "../Services/apis";
import Casino from "../Assets/img/cas.jpeg";
import JackpotLayout from "./layout/JackpotLayout";

export default function ViewMoreGames({ history }) {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [mode, setMode] = useState(0);
  const [token, setToken] = useState("111111");
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState([]);
  const [group, setGroup] = useState(process.env.REACT_APP_SITE_KEY);

  let { slug } = useParams();

  useEffect(() => {
    if (isAuthenticated) {
      setToken(user.auth_code);
      setMode(1);
      setGroup(user.group);
    } else {
      setToken("111111");
      setMode(0);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {}, [token, mode]);

  const fetchGames = () => {
    setLoading(true);

    getTopCasinoGame()
      .then((response) => {
        setLoading(false);
        setGames(response?.data);
      })
      .catch((err) => {
        setGames(err?.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const viewDetails = (id) => {
    if (isAuthenticated) {
      history.push(`/play/live-casino/${id}`);
    } else {
      history.replace("/Auth/Register");
    }
  };
  return (
    <JackpotLayout>
      <div className="box-holder">
        {games &&
          games?.map((item, i) =>
            item?.slug === slug
              ? item?.games &&
                item?.games?.map((game, i) => (
                  <div
                    className="box"
                    key={i}
                    onClick={() => viewDetails(game?.casino?.game_id)}
                  >
                    <img
                      src={
                        game?.casino?.image_path === null
                          ? Casino
                          : game?.casino?.image_path
                      }
                      alt="view"
                    />
                    <div class="middle">
                      <p>{game?.casino?.title}</p>
                      <button class="textt">Play</button>
                    </div>
                  </div>
                ))
              : ""
          )}
      </div>
    </JackpotLayout>
  );
}
